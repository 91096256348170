import React from "react";

import DocsHelmet from "components/docs/shared-components/DocsHelmet";
import HeaderBar from "components/docs/layout/HeaderBar";
import CrossGuideLinks from "components/docs/navigation/CrossGuideLinks";
import { Link, PageProps } from "gatsby";
import DocumentationSection from "components/docs/DocumentationSection";
import LINKS from "components/docs/navigation/links";
import { StaticPageContainer } from "components/docs/shared-components/PageContainers";
import ConfigureTargetInLink from "../../../assets/images/docs/guides/field-mapping/ConfigureTargetInLink.svg";
import IntWideFieldMappingTargets from "../../../assets/images/docs/guides/field-mapping/IntWideFieldMappingTargets.svg";
import AllowableFields from "../../../assets/images/docs/guides/field-mapping/AllowableFields.svg";
import { Text } from "@merge-api/merge-javascript-shared";
import { Info } from "lucide-react";

/**
 * Creates a Target Fields page for Field Mapping guide
 */
const TargetFieldsFieldMappings = ({
  location,
}: PageProps<unknown, unknown, Record<string, unknown> | null>) => {
  const title = "Target fields";
  const description =
    "Target fields are fields on your Merge Common Models that you can map your users' third-party fields to";

  return (
    <StaticPageContainer style={{ paddingBottom: "0" }}>
      <DocsHelmet title={title} description={description} />
      <HeaderBar title={title} subtitle={description} />
      <DocumentationSection title="Add Target fields to any Merge Common Models">
        <>
          <div className="pb-0 mb-9 mt-6 bg-[#FCFDFF] rounded-lg px-6 py-5 border-gray-10 border">
            <Text variant="h6">Types of Target Fields</Text>
            <div className="d-flex">
              <i className="fe fe-globe mr-1.5 text-orange-50" />{" "}
              <p>
                <span className="text-orange-50">Organization-wide target fields </span>
                can be created and configured by you in the{" "}
                <a href="https://app.merge.dev/configuration/field-mappings/target-fields">
                  Field Mapping
                </a>{" "}
                page in Advanced Configuration. These fields apply to all Linked Accounts in a
                category.
              </p>
            </div>
            <div className="d-flex">
              <i className="fe fe-user mr-1.5 text-yellow-50" />{" "}
              <p>
                <span className="text-yellow-50">Linked Account-specific target fields</span> can be
                created and configured either by you in your user’s Linked Account Field Mapping
                page or by your user in Merge Link. These fields are specific to the Linked Account
                they are created for. See how to{" "}
                <Link to={LINKS.MERGE_FIELD_MAPPING_CREATE_MAPPING_FOR_LINKED_ACCOUNT.linkTo}>
                  create a Field Mapping for a Linked Account.
                </Link>
              </p>
            </div>{" "}
            <div className="d-flex">
              <i className="fe fe-box mr-1.5 text-blue-40" />{" "}
              <p>
                <span className="text-blue-40">Common Model override fields</span> are a subset of
                target fields and can be organization-wide or Linked Account-specific.
              </p>
            </div>{" "}
          </div>
          <p className="mb-0">
            To define an organization-wide target field, go to{" "}
            <a href="https://app.merge.dev/configuration/field-mappings/target-fields">
              Field Mapping
            </a>{" "}
            in Advanced configuration in your Merge dashboard.
          </p>
          <div className="w-full flex items-center justify-center my-9">
            <IntWideFieldMappingTargets />
          </div>
          <p>
            Click <b>+ Field</b> in the upper right, then select the type of target field you want
            to add. Select <b>Add organization-wide target field</b> if you want to append a field
            to your Merge Common Model. Select <b>Add Common Model override field</b> if you want to
            override an existing Merge field.
          </p>
          <p>
            Select the Common Model you want, name or select the field you want, and click{" "}
            <b>Save</b>. The field name you define will later be returned in the object response
            from Merge's Unified API. We recommend using underscores in your field name (eg.
            technical_assessment_score, etc.).
          </p>
          <p>
            <b>Note:</b> A target field's name cannot be changed after it is set. If you want to
            change it, you will need to delete it and create a new one, which will have to be
            re-mapped.
          </p>
          <div className="bg-gray-0 rounded-lg px-6 py-5">
            <div className="flex flex-row items-center">
              <Info size={16} className="mr-1.5" />
              <div>
                You can get all organization-wide target fields for a category via API using the
                endpoint{" "}
                <Link to="https://docs.merge.dev/hris/field-mapping/#target_fields_retrieve">
                  GET /target-fields
                </Link>
                .
              </div>
            </div>
          </div>
        </>
      </DocumentationSection>
      <hr className="my-9" />
      <DocumentationSection className="mb-3" title="Show target fields in Link">
        <>
          <p>
            Click on an organization-wide target field to view its details. Under{" "}
            <strong>Configure field in Merge Link</strong>, you can customize the mapping experience
            for your users:
          </p>
          <ul>
            <li>
              Target fields with <strong>Mappable in Link</strong> enabled will appear in the Field
              Mapping workflow in Link.
            </li>
            <li className="mt-3">
              Target fields with <strong>Prioritized in Link</strong> enabled will appear at the
              beginning of the workflow or top of the list, depending on how many mappable targets
              you have. If you have multiple prioritized target fields, they will appear in
              alphabetical order.
            </li>
          </ul>
          <div className="w-full flex items-center justify-center mt-9 mb-9">
            <ConfigureTargetInLink className="shadow-md rounded-lg" />
          </div>
          <p>
            <b>Note:</b> Common Model override fields cannot be configured to appear in Merge Link.
          </p>
        </>
      </DocumentationSection>
      <hr className="my-6" />
      <DocumentationSection title="User-created target fields" className="my-6">
        <p>
          Configure whether or not your end users can define their own targets in Link by enabling{" "}
          <strong>Allow end user-created target fields</strong> for a Common Model. User-created
          fields will only appear in the Field Mapping section of each Linked Account page, and will
          not appear in your organization’s target fields.
        </p>

        <div className="w-full flex items-center justify-center mt-9">
          <AllowableFields />
        </div>
      </DocumentationSection>
      <CrossGuideLinks location={location} usesQuaternaryLinks />
    </StaticPageContainer>
  );
};

export default TargetFieldsFieldMappings;
